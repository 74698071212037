import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import type {
  Stripe,
  StripeCardCvcElement,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElement,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
  StripeElementBase,
} from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Button } from "clutch/src/Button/Button.jsx";

import { readState } from "@/__main__/app-state.mjs";
import getData, { postData } from "@/__main__/get-data.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { EVENT_ERROR } from "@/app/ErrorBoundary.jsx";
import getBearerToken from "@/feature-auth/utils/get-auth-request-header.mjs";
import { addPaymentMethod } from "@/feature-wallet/actions.mjs";
import { createPaymentMethod } from "@/feature-wallet/api.mjs";
import { STRIPE_PUBLISHABLE_KEY } from "@/feature-wallet/constants.mjs";
import type { CardBrand } from "@/feature-wallet/models/card-brand.mjs";
import { CreatePaymentMethodModel } from "@/feature-wallet/models/create-payment-method.mjs";
import type { StripeCreatePaymentMethodError } from "@/feature-wallet/models/stripe-create-payment-method.mjs";
import { StripeCreatePaymentMethodModel } from "@/feature-wallet/models/stripe-create-payment-method.mjs";
import { FormSubmit, PaymentTypeIcon } from "@/feature-wallet/Shared.jsx";
import BlitzInfo from "@/inline-assets/blitz-info.svg";
import Lock from "@/inline-assets/blitz-lock.svg";
import { devError } from "@/util/dev.mjs";

const Styled = {
  Form: styled("form")`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: var(--sp-6);
    column-gap: var(--sp-3);

    & .full-width {
      grid-column: span 3;
    }

    & .error-hint {
      color: var(--red);
      text-align: center;
    }

    & .input-group {
      display: flex;
      flex-direction: column;
      gap: var(--sp-2);
    }

    & .input-wrapper {
      border-radius: var(--br);
      border: 1px solid var(--shade6);
      width: 100%;

      padding: var(--sp-3_5) var(--sp-4);
      display: flex;
      align-items: center;
      gap: var(--sp-4);

      background-color: var(--shade8);

      &.error {
        border: 1px solid var(--red);

        & .input {
          color: #e03f54;
        }
      }

      & .input {
        height: var(--sp-4);
        width: 100%;

        color: #e5ebef;
        font-family: "Maison Neue", sans-serif;
        font-weight: 400;
        font-size: 16px;

        &::placeholder {
          color: #7f838b;
        }

        &.invalid {
          color: #e03f54;
        }
      }
    }
  `,
};

// Unfortunately these have to stay as hex bc of iframe nonsense
const STRIPE_STYLE_OVERRIDES = {
  style: {
    base: {
      color: "#e5ebef",
      fontWeight: 400,
      fontFamily: "Maison Neue, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#e5ebef",
      },
      "::placeholder": {
        color: "#7F838B",
      },
    },
    invalid: {
      color: "#e03f54",
    },
  },
};

interface AddCardFormInputState {
  complete: boolean;
  error: null | {
    type: "validation_error";
    code: string;
    message: string | Translation;
  };
}

interface StripeElementInputState<E extends StripeElementBase>
  extends AddCardFormInputState {
  element: E;
}

interface PostalCodeInputState extends AddCardFormInputState {
  value: string;
}

interface AddCardFormInputs {
  cardBrand: CardBrand;
  cardNumber: StripeElementInputState<StripeCardNumberElement>;
  cardExpiry: StripeElementInputState<StripeCardExpiryElement>;
  cardCvc: StripeElementInputState<StripeCardCvcElement>;
  postalCode: PostalCodeInputState;
}

const AddCardFormContext =
  React.createContext<
    [AddCardFormInputs, React.Dispatch<React.SetStateAction<AddCardFormInputs>>]
  >(null);

export type AddCardFormSubmitError =
  | StripeCreatePaymentMethodError
  | Translation
  | string
  | null;

export type AddCardSubmitEvent =
  | { status: "invalid" }
  | { status: "valid" }
  | { status: "submitting" }
  | { status: "success"; paymentMethodId: string }
  | {
      status: "error";
      error: StripeCreatePaymentMethodError | Translation | string;
    };

interface AddCardFormProps {
  onSubmitting?: () => void;
  onError?: (error: AddCardFormSubmitError) => void;
  onSuccess?: (paymentMethodId: string) => void;

  onSubmitEvent?: (event: AddCardSubmitEvent) => void;
}

export function AddCardForm(props: React.PropsWithChildren<AddCardFormProps>) {
  return (
    <StripeElements>
      <InnerAddCardForm {...props} />
    </StripeElements>
  );
}

function InnerAddCardForm({
  onSubmitting,
  onError,
  onSuccess,
  onSubmitEvent,
  children,
}: React.PropsWithChildren<AddCardFormProps>) {
  const stripe = useStripe();

  const [formState, setFormState] = useState<AddCardFormInputs>({
    cardBrand: "unknown",
    cardNumber: { complete: false, error: null, element: null },
    cardExpiry: { complete: false, error: null, element: null },
    cardCvc: { complete: false, error: null, element: null },
    postalCode: { complete: false, error: null, value: "" },
  });

  const [submitState, setSubmitState] = useState<AddCardSubmitEvent>({
    status: "invalid",
  });

  const isComplete = useMemo(() => {
    return (
      formState.cardNumber.complete &&
      formState.cardExpiry.complete &&
      formState.cardCvc.complete &&
      formState.postalCode.complete
    );
  }, [
    formState.cardNumber.complete,
    formState.cardExpiry.complete,
    formState.cardCvc.complete,
    formState.postalCode.complete,
  ]);

  useEffect(() => {
    if (isComplete && stripe) {
      setSubmitState({ status: "valid" });
    } else {
      setSubmitState({ status: "invalid" });
    }
  }, [isComplete, stripe]);

  useEffect(() => {
    if (submitState.status === "submitting") {
      onSubmitting?.();
    }
  }, [submitState, onSubmitting, onSubmitEvent]);

  useEffect(() => {
    if (submitState.status === "success") {
      onSuccess?.(submitState.paymentMethodId);
    }
  }, [submitState, onSuccess]);

  useEffect(() => {
    if (submitState.status === "error") {
      onError?.(submitState.error);
    }
  }, [submitState, onError]);

  useEffect(() => {
    onSubmitEvent?.(submitState);
  }, [submitState, onSubmitEvent]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (submitState.status !== "valid" && submitState.status !== "error") {
        return;
      }

      const bearerToken = await getBearerToken();

      if (!isComplete || !stripe || !bearerToken) {
        return;
      }

      setSubmitState({ status: "submitting" });

      try {
        const { paymentMethod, error } = await getData(
          stripe.createPaymentMethod({
            type: "card",
            card: formState.cardNumber.element,
            billing_details: {
              address: {
                postal_code: formState.postalCode.value,
              },
            },
          }),
          StripeCreatePaymentMethodModel,
          undefined,
        );

        if (error) {
          setSubmitState({ status: "error", error });

          eventBus.emit(EVENT_ERROR, {
            error,
            tags: ["wallet", "stripe_create_payment_method"],
          });

          return;
        }

        const { testClock } = readState.volatile;

        const newPaymentMethod = await postData(
          createPaymentMethod({
            isDefault: true,
            paymentMethodId: paymentMethod.id,
            providerType: "STRIPE",
            testClock: testClock?.id,
          }),
          CreatePaymentMethodModel,
          undefined,
          { headers: { Authorization: bearerToken } },
        );

        if (newPaymentMethod instanceof Error) throw newPaymentMethod;

        addPaymentMethod(newPaymentMethod);

        setSubmitState({
          status: "success",
          paymentMethodId: newPaymentMethod.id,
        });
      } catch (e: unknown) {
        devError("Error creating payment method", e);

        if (
          e instanceof Object &&
          "errorMessage" in e &&
          typeof e.errorMessage === "string"
        ) {
          setSubmitState({ status: "error", error: e.errorMessage });
        } else {
          setSubmitState({
            status: "error",
            error: [
              "common:error.serverError",
              "Server error. Check our Discord.",
            ],
          });
        }

        eventBus.emit(EVENT_ERROR, {
          error: e,
          tags: ["wallet", "create_payment_method"],
        });
      }
    },
    [submitState.status, isComplete, stripe, formState],
  );

  return (
    <StripeElements>
      <AddCardFormContext.Provider value={[formState, setFormState]}>
        <Styled.Form onSubmit={onSubmit}>{children}</Styled.Form>
      </AddCardFormContext.Provider>
    </StripeElements>
  );
}

export function AddCardFormBody() {
  const elements = useElements();

  const [
    { cardBrand, cardNumber, cardCvc, cardExpiry, postalCode },
    setFormState,
  ] = useContext(AddCardFormContext);

  const { t } = useTranslation();

  const onChangeCardNumber = useCallback(
    ({ brand, complete, error }: StripeCardNumberElementChangeEvent) => {
      setFormState((prev) => ({
        ...prev,
        cardBrand: brand,
        cardNumber: {
          complete,
          error,
          element: elements.getElement("cardNumber"),
        },
      }));
    },
    [elements, setFormState],
  );

  const onChangeCardExpiry = useCallback(
    ({ complete, error }: StripeCardExpiryElementChangeEvent) => {
      setFormState((prev) => ({
        ...prev,
        cardExpiry: {
          complete,
          error,
          element: elements.getElement("cardExpiry"),
        },
      }));
    },
    [elements, setFormState],
  );

  const onChangeCardCvc = useCallback(
    ({ complete, error }: StripeCardCvcElementChangeEvent) => {
      setFormState((prev) => ({
        ...prev,
        cardCvc: { complete, error, element: elements.getElement("cardCvc") },
      }));
    },
    [elements, setFormState],
  );

  const onChangePostalCode = useCallback(
    ({ complete, error, value }: PostalCodeInputState) => {
      setFormState((prev) => ({
        ...prev,
        postalCode: { complete, error, value },
      }));
    },
    [setFormState],
  );

  useEffect(() => {
    if (!elements) return;

    setFormState((prev) => ({
      ...prev,
      cardNumber: {
        ...prev.cardNumber,
        element: elements.getElement("cardNumber"),
      },
      cardExpiry: {
        ...prev.cardExpiry,
        element: elements.getElement("cardExpiry"),
      },
      cardCvc: {
        ...prev.cardCvc,
        element: elements.getElement("cardCvc"),
      },
    }));
  }, [elements, setFormState]);

  return (
    <>
      <div className="input-group full-width">
        <label htmlFor="cardNumber" className="type-caption shade1">
          {t("common:wallet.cardNumber", "Card Number")}
        </label>

        <div className={`input-wrapper ${cardNumber.error ? "error" : ""}`}>
          <CardNumberElement
            id="cardNumber"
            className="input"
            options={STRIPE_STYLE_OVERRIDES}
            onChange={onChangeCardNumber}
          />
          <PaymentTypeIcon
            className="card-icon"
            type="card"
            cardBrand={cardBrand}
          />
          <Lock />
        </div>
      </div>

      <div className="input-group">
        <label htmlFor="cardExpiry" className="type-caption shade1">
          {t("common:wallet.expirationDate", "Expiration Date")}
        </label>
        <div className={`input-wrapper ${cardExpiry.error ? "error" : ""}`}>
          <CardExpiryElement
            id="cardExpiry"
            className="input"
            options={STRIPE_STYLE_OVERRIDES}
            onChange={onChangeCardExpiry}
          />
        </div>
      </div>

      <div className="input-group">
        <label
          htmlFor="cardCvc"
          className="type-caption shade1 flex align-center gap-1"
        >
          {t("common:wallet.securityCode", "Security Code")}
          <div
            data-tip={t(
              "common:wallet.securityCodeInfo",
              "Your card's security code (CVV) is the 3 or 4 digit number located on the back of most cards.",
            )}
          >
            <BlitzInfo />
          </div>
        </label>

        <div className={`input-wrapper ${cardCvc.error ? "error" : ""}`}>
          <CardCvcElement
            id="cardCvc"
            className="input"
            options={STRIPE_STYLE_OVERRIDES}
            onChange={onChangeCardCvc}
          />
        </div>
      </div>

      <div className="input-group">
        <label htmlFor="postalCode" className="type-caption shade1">
          {t("common:wallet.postalCode", "Postal Code")}
        </label>
        <div className={`input-wrapper ${postalCode.error ? "error" : ""}`}>
          <FakeStripePostalInput
            className="input"
            onChange={onChangePostalCode}
          />
        </div>
      </div>
    </>
  );
}

const POSTAL_PATTERN = /^[a-z0-9]+$/i;

function FakeStripePostalInput({
  onChange,
  className,
}: {
  onChange: (e: PostalCodeInputState) => void;
  className?: string;
}) {
  const [postalCode, setPostalCode] = useState("");

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (value && !POSTAL_PATTERN.test(value)) return;

      setPostalCode(value);

      onChange({
        complete: value.length >= 4,
        error: null,
        value,
      });
    },
    [onChange],
  );

  const handleBlur = useCallback(
    (e) => {
      const value = e.target.value;
      const invalid = Boolean(value.length && value.length < 4);

      onChange({
        complete: Boolean(value && !invalid),
        error: invalid
          ? {
              type: "validation_error",
              code: "incomplete_postal",
              message: [
                "common:settings.paymentBillingPage.error.zipCodeLength",
                "Postal code must be between 4-9 characters",
              ],
            }
          : null,
        value,
      });
    },
    [onChange],
  );

  return (
    <input
      id="postalCode"
      type="text"
      className={className}
      placeholder="12345"
      maxLength={16}
      value={postalCode}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}

export function AddCardFormSubmitButton({
  canSubmit,
  children,
}: React.PropsWithChildren<{ canSubmit: boolean }>) {
  return (
    <div className="full-width flex column gap-4">
      <Button
        type="submit"
        emphasis="high"
        disabled={!canSubmit}
        bgColor="var(--shade0)"
        bgColorHover="var(--shade0-75)"
        textColor="var(--shade7)"
      >
        {children}
      </Button>
    </div>
  );
}

export function AddCardFormSubmitting() {
  return (
    <div className="full-width flex column gap-4">
      <FormSubmit.Loading />
    </div>
  );
}

export function AddCardFormError({ error }: { error: AddCardFormSubmitError }) {
  const { t } = useTranslation();

  return (
    <p className="type-subtitle2 error-hint full-width" aria-live="polite">
      {typeof error === "string"
        ? error
        : Array.isArray(error)
          ? t(...error)
          : error.message}
    </p>
  );
}

let stripeInstance: Stripe | null = null;

let initStripePromise: Promise<Stripe> | null = null;

function initStripe() {
  if (!initStripePromise) {
    initStripePromise = (async () => {
      // TODO: investigate why this is needed
      loadStripe.setLoadParameters({ advancedFraudSignals: false });
      stripeInstance = await loadStripe(STRIPE_PUBLISHABLE_KEY);
      return stripeInstance;
    })();
  }

  return initStripePromise;
}

function StripeElements({ children }: React.PropsWithChildren) {
  const [stripe, setStripe] = useState(stripeInstance);

  useLayoutEffect(() => {
    if (stripe) return;

    let isMounted = true;

    queueMicrotask(async () => {
      if (isMounted) {
        setStripe(await initStripe());
      }
    });

    return () => {
      isMounted = false;
    };
  }, [stripe]);

  return <Elements stripe={stripe}>{children}</Elements>;
}
