import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Optional } from "@/__main__/data-model.mjs";

const errorModel = {
  code: String,
  message: String,
} satisfies ModelDefinition;

const model = {
  paymentMethod: Optional({
    id: String,
  }),

  error: Optional(errorModel),
} satisfies ModelDefinition;

export const StripeCreatePaymentMethodModel = createModel(model);

export interface StripeCreatePaymentMethod
  extends FromModel<typeof StripeCreatePaymentMethodModel> {}

export interface StripeCreatePaymentMethodError
  extends FromModel<typeof errorModel> {}
