import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import type { AddCardSubmitEvent } from "@/feature-wallet/AddCardForm.jsx";
import {
  AddCardForm,
  AddCardFormBody,
  AddCardFormError,
  AddCardFormSubmitButton,
  AddCardFormSubmitting,
} from "@/feature-wallet/AddCardForm.jsx";

export default function AddCardDialogViewBody({
  onAddedPaymentMethod,
}: {
  onAddedPaymentMethod(paymentMethodId: string): void;
}) {
  const [submitState, setSubmitState] = useState<AddCardSubmitEvent>({
    status: "invalid",
  });

  const { t } = useTranslation();

  return (
    <AddCardForm
      onSuccess={onAddedPaymentMethod}
      onSubmitEvent={setSubmitState}
    >
      <AddCardFormBody />

      {submitState.status === "error" ? (
        <AddCardFormError error={submitState.error} />
      ) : submitState.status === "submitting" ? (
        <AddCardFormSubmitting />
      ) : (
        <AddCardFormSubmitButton canSubmit={submitState.status === "valid"}>
          {t("common:wallet.addCard", "Add card")}
        </AddCardFormSubmitButton>
      )}
    </AddCardForm>
  );
}
